<template>
  <div>
    <div>
      <div class="class_nav">
        <span>当前位置：</span> <span>作品管理</span> > <span>{{ title }}</span>
      </div>
      <div class="course-nav dis-flex">
        <div class="course-nav-item dis-flex">
          <h3>班级：</h3>
          <div>
            <el-input disabled v-model="class_name" placeholder=""></el-input>
          </div>
        </div>
		<div  class="course-nav-item dis-flex">
		  <h3>学生：</h3>
		  <div>
		    <el-select v-model="class_values" placeholder="请选择学生">
		      <el-option
		          v-for="item in optionss"
		          :key="item.id"
		          :label="item.student_name"
		          :value="item.id">
		      </el-option>
		    </el-select>
		  </div>
		</div>
        <div class="course-nav-item dis-flex">
          <h3>课程：</h3>
          <div>
			  <div>
			    <el-select v-model="course_id" placeholder="请选择课程">
			      <el-option
			          v-for="item in course"
			          :key="item.id"
			          :label="item.title"
			          :value="item.id">
			      </el-option>
			    </el-select>
			  </div>
            <!-- <el-input disabled v-model="course_name" placeholder=""></el-input> -->
          </div>
        </div>
        
      </div>
      <div class="upworks ">
        <h3>上传作品</h3>
        <div class=" margin-auto-20">
          <div style="width: 500px">
            <el-upload
                ref="upload"
                class="upload-demo"
                action="https://kechuang.dbbbkj.top/teacher/upload"
                :headers="headers"
                :limit=limit
                :multiple="multiple"
                :on-success="upSuccess"
                :show-file-list="false"
                list-type="picture"
                :before-upload="beforeUploads"
                :on-error="upError"
                :on-remove='removeImg'
            >
              <el-button size="small" type="primary"
                         style="background-color:#CCCCCC!important;
                                       color: #FF8E42!important;
                                       ;border-radius: 3px!important;
                                       border: 0!important;"
              >点击选择上传图片
              </el-button>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="avatarImg">

        <div class=" divBox">
          <div class="divBox-img"
               v-for="(item,index) in studentfile" :key="index">
            <img :src="item" v-if="item" width="148"
                 height="148" class="imgSty">
            <i
                class="el-icon-circle-close delIcon"
                @click="deleImg(item,index)"></i>
          </div>

        </div>
      </div>
    </div>
    <div @click="uploadFiles" class=" upclick cursor-pointer">上传</div>
  </div>
</template>

<script>
import api from '@/service/api/api'

export default {
  name: "upworks",
  data() {
    return {
      works: [
        {id: '', img: '', name: '2021油画公开课01', text: ''}
      ],
      class_name:'',
      course_name:'',
	  course:[],
      title: '',
      class_values: '',
	  course_id: '',
      optionss: [],

      // 上传
      studentfile: [],
      multiple: true,
      headers: {
        acctid: localStorage.getItem("acctid"),
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
      limit: 20,  // 上传excell时，同时允许上传的最大数

    }
  },
  created() {
    this.title = this.$route.query.title
    this.course_id = this.$route.query.course_id
	this.class_values=this.$route.query.classmember_id
    this.classseletinit()
  },
  methods: {
    // 班级选择初始化接口
    classseletinit() {
      let params = {
        class_id:this.$route.query.class_id,
        course_id:this.$route.query.course_id
        
      }
      api.classseletinit(params).then(res => {
        if (res.errcode === 0) {
          this.optionss = res.data.classmember
          this.class_name = res.data.class_name
          this.course = res.data.course
          // this.course_name = res.data.course.title
          console.log(this.options[0].classmember)


        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },

    // 上传前判断
    beforeUploads(file) {
      console.log(file)
      let result = [];
      for (let i = 0; i < 8; i++) {
        let ranNum = Math.ceil(Math.random() * 25); //生成一个0到25的数字
        //大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
        result.push(String.fromCharCode(65 + ranNum));
      }
      // console.log(result)
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message({
          message: "上传头像图片只能是 JPG/PNG 格式!",
          duration: 3000,
          type: "warning"
        });
        // alert("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt10M) {
        this.$message({
          message: "上传头像图片大小不能超过 10MB!",
          duration: 3000,
          type: "warning"
        });
        // alert("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt10M;
    },
    // 上传成功
    upSuccess(res, file) {
      console.log(res)
      this.studentfile.push(res.data.url)
      // console.log(1111, this.studentfile)
      this.studentfile.sort(function (m, n) {

        if (m < n) return -1
        // => a.match(/(?<=com\/).+(?=\.jpg)/)[0] - b.match(/(?<=com\/).+(?=\.jpg)/)[0])
        else if (m > n) return 1
        else return 0
      })
      // console.log(222, this.studentfile)
    },
    // 上传失败
    upError(res, file) {
      if (res) {
        this.$message({
          message: "上传失败",
          duration: 2000,
          type: "warning"
        });
      }
    },
    // 删除缩略图
    removeImg(file, fileList) {
      this.studentfile.splice(index, 1)
      console.log()
    },
    // 删除
    deleImg(data, index) {
      this.studentfile.splice(index, 1)
    },
    // 覆盖默认的上传行为，自定义上传的实现
    uploadFiles() {
      let params = {
        work_pic: this.studentfile.join(','),
        class_id: this.$route.query.class_id,
        // course_id: this.$route.query.course_id,
        course_id: this.course_id,
        class_member_id: this.class_values
      }
      console.log(params)
      if (this.$route.query.class_id && this.class_values && this.course_id) {
        api.zyup(params).then(res => {
          console.log(res)
          if (res.errcode === 0) {
            this.$message({
              message: '上传成功',
              duration: 2000,
              type: "succeed"
            });
          } else {
            this.$message({
              message: res.errmsg,
              duration: 2000,
              type: "warning"
            });
          }
        })
      } else {
        this.$message.warning('请选择班级-学生-课程')
      }
    },
  }
}
</script>

<style scoped>
.class_nav {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  color: #FF8E42;
}

.class_nav span:first-child {
  color: #999999;
}

.course-nav {
  width: 1200px;
  height: 40px;
  line-height: 40px;
  margin: 20px auto;
  /*background-color: #4A80F7;*/
}

.course-nav-item {
  color: #999999;
  font-size: 16px;
  margin-right: 20px;
}
.upworks {
  width: 1200px;
  margin: 0 auto;
}
.upworks > h3 {
  width: 80px;
  height: 50px;
  line-height: 60px;
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  border-bottom: 1px solid #FF8E42;
}

.upclick {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  background-color: #FF8E42;
  margin: 30px auto;
  border-radius: 4px;
}

.avatarImg {
  width: 100%;
  height: 100%;
}

.divBox {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;


}

.divBox-img {
  margin: 15px 0 15px 20px;
  position: relative;
}

.imgSty {
  margin-left: 3px;
}

.delIcon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 25px;
  background-color: #FB3838;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}
</style>